import React from 'react'

function ContactUs() {
  return (
    <div>
      this is contaCT US
    </div>
  )
}

export default ContactUs
